import { type ComponentProps } from 'react'
import { type CMSPage, CMSPageError } from '@/components/CMS/CMSPage'
import { CMSSection } from '@/components/CMS/CMSSection'
import { NoDataState } from '@/components/CMS/components/Layouts/NoDataState'
import { type FooterData, type Section } from '@/components/CMS/types'
import { DocumentHead } from '@/components/Marketing/DocumentHead'

export type PartialCMSPageChildrenRenderProps = ({
  body,
  footer,
}: {
  body: Section | undefined
  footer: FooterData[] | undefined
}) => React.ReactNode

type Props = {
  cmsData: ComponentProps<typeof CMSPage>['cmsData']
  children?: PartialCMSPageChildrenRenderProps
}

export const PartialCMSPageWithHeaderAndAnnouncement = ({
  cmsData,
  children,
}: Props) => {
  if (!cmsData || typeof cmsData !== 'object' || 'error' in cmsData) {
    return <CMSPageError statusCode={cmsData.error?.status} />
  }
  if (cmsData.content?.content_type_id !== 'page_layout') {
    return <NoDataState />
  }
  const { header, announcement, body, footer } = cmsData.content.data ?? {}

  return (
    <>
      <DocumentHead
        title={cmsData.seo_title}
        description={cmsData.seo_description}
      />
      {announcement && <CMSSection section={announcement} />}
      {header && <CMSSection section={header} />}
      {children?.({ body, footer })}
    </>
  )
}
