import { queryOptions, useQuery } from '@tanstack/react-query'
import {
  fetchCMSCatNavData,
  fetchCMSData,
  fetchDeferredHydration,
} from '@/services/CMS/fetchers'
import { type StoreParams } from '@/utils/dataFetching/storeParams'
import { useRouter } from 'next/router'
import { type getAddressParamsForCMS } from '@/services/CMS/utils'
import { useCMSParamsWithoutSlug } from '@/services/CMS/hooks'
import { useIsDataRightsRelatedQueryEnabled } from '@/services/DataPrivacy/hooks'
import { type Content } from '@/components/CMS/types'

type StoreParamsAndAddressParams = {
  storeParams?: StoreParams
  addressParams?: ReturnType<typeof getAddressParamsForCMS>
}
export const cmsQueryOptions = ({
  slug,
  storeParams,
  addressParams,
  enableDeferredShelves,
}: StoreParamsAndAddressParams & {
  slug: string
  enableDeferredShelves: boolean
}) => {
  return queryOptions({
    queryKey: [
      `CMS_${slug}`,
      { slug, storeParams, addressParams, enableDeferredShelves },
    ],
    queryFn: fetchCMSData,
  })
}

export const useQueryCMS = ({
  params,
  enabled,
  keepPreviousData,
}: {
  params: ReturnType<typeof useCMSParams>
  enabled: boolean
  keepPreviousData?: boolean
}) => {
  return useQuery({
    ...cmsQueryOptions(params),
    enabled: useIsDataRightsRelatedQueryEnabled(enabled),
    placeholderData: (prevData) => (keepPreviousData ? prevData : undefined),
  })
}

export const catNavCmsQueryOptions = ({
  storeParams,
  addressParams,
}: StoreParamsAndAddressParams) =>
  queryOptions({
    queryKey: [
      'CMS_CAT_NAV',
      { storeParams, addressParams, enableDeferredShelves: false },
    ],
    queryFn: fetchCMSCatNavData,
  })

export const useQueryCatNavCMS = () => {
  const { storeParams, addressParams } = useCMSParams()
  return useQuery({
    ...catNavCmsQueryOptions({ storeParams, addressParams }),
    enabled: Boolean(storeParams.store_location_id),
  })
}

export const useCMSParams = (enableDeferredShelves: boolean = false) => {
  const { asPath } = useRouter()
  const cmsParamsWithoutSlug = useCMSParamsWithoutSlug()
  return {
    ...cmsParamsWithoutSlug,
    slug: asPath.slice(1).split('?')[0],
    enableDeferredShelves,
  }
}

export const useFetchDeferredHydration = (
  hydrationCallback: Content['hydration_callback']
) => {
  return useQuery({
    queryFn: () =>
      fetchDeferredHydration(
        hydrationCallback ?? {
          url: '',
          headers: {},
          body: {},
        }
      ),
    queryKey: ['DeferredCallback', hydrationCallback?.url ?? ''],
    enabled: !!hydrationCallback,
  })
}
